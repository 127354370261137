export const fillRange = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((item, index) => start + index)
}

const makeQRLink = (redirect, url, num) => {
  switch (redirect) {
    case 'msj':
      return `https://redirect.msj.world/qr/${url}/${num}`
    case 'custom':
      return `https://${url}/${num}`
    case 'page':
      return `https://redirect.msj.world/qr/${url}/card/${num}`
    default:
      return url
  }
}

const makeShortLink = (redirect, url, num) => {
  switch (redirect) {
    case 'msj':
      return `msj.world/${url}/${num}`
    case 'custom':
      return `${url}/${num}`
    case 'page':
      return `msj.world/${url}/card/${num}`
    default:
      return url
  }
}

/**
 * Takes form values and populates two sets of urls, one for QR, one tinyURL.
 *
 * @param {string}      redirect
 * @param {string}      url
 * @param {string}      start
 * @param {string}      end
 *
 * @returns {Array}
 */
export const linkGenerator = (redirect, url, start, end) => {
  switch (redirect) {
    case 'url':
      return [
        {
          qrLink: url,
          shortUrl: url,
          num: 0,
          url: url,
        },
      ]

    case 'page':
      return [
        {
          qrLink: makeQRLink(redirect, url, start),
          shortUrl: makeShortLink(redirect, url, start),
          num: 0,
          url: url,
        },
      ]

    default:
      const range = fillRange(Number(start), Number(end))
      const values = []
      range.map((num) => {
        const val = {
          qrLink: makeQRLink(redirect, url, num),
          shortUrl: makeShortLink(redirect, url, num),
          num: num,
          url: url,
        }
        return values.push(val)
      })
      return values
  }
}

/**
 * Takes QR url and renders canvas which is then downloaded.
 *
 * @param {object}      url
 * @param {object}      canvasRef
 *
 * @returns {png}
 */
export const downloadQRPng = (url, canvasRef) => {
  const canvas = canvasRef
  const pngUrl = canvas
    .toDataURL('image/png')
    .replace('image/png', 'image/octet-stream')

  const downloadLink = document.createElement('a')
  downloadLink.href = pngUrl

  downloadLink.download = `${url.num}-qrcode-${url.url}.png`
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

/**
 * Takes blob created by downloadSVG and returns svg.
 *
 * @param {object}      blob
 * @param {string}      filename
 *
 * @returns {svg}
 */
export const downloadBlob = (blob, filename) => {
  const objectUrl = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.href = objectUrl
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000)
}

/**
 * Takes url from QR code and writes svg tag, then creates blob which is sent to downloadBlob.
 *
 * @param {object}      url
 * @param {object}      svgRef
 *
 * @returns {svg}
 */
export const downloadSVG = (url, svgRef) => {
  const svgElement = svgRef.current
  svgElement.firstElementChild.setAttribute(
    'xmlns',
    'http://www.w3.org/2000/svg'
  )
  const svg = svgElement.innerHTML
  const blob = new Blob([svg], { type: 'image/svg+xml' })
  downloadBlob(blob, `${url.num}-qrcode-${url.url}.svg`)
}
