import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 18,
    backgroundColor: '#F8F8F8',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
      maxWidth: '100%',
      minWidth: 280,
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 12,
  },
}))

const InfoCard = ({ t, label, forLabel, useCaseLabel, onClick, selected }) => {
  const classes = useStyles()

  return (
    <Card className={classes.root} onClick={onClick}>
      <CardContent>
        {selected ? (
          <div className={classes.row}>
            <span style={{ color: '#E64770' }}>♡&nbsp;</span>
            <Typography
              variant="h5"
              component="h2"
              style={{ color: '#E64770' }}
            >
              {label}
            </Typography>
            <span style={{ color: '#E64770' }}>&nbsp;♡</span>
          </div>
        ) : (
          <Typography variant="h5" component="h2" className={classes.pos}>
            {label}
          </Typography>
        )}

        <Typography className={classes.pos} style={{ color: '#3f6cb3' }}>
          {forLabel}
        </Typography>

        <Typography variant="body2" component="p">
          {useCaseLabel}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default withNamespaces()(InfoCard)
