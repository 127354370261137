import { useState } from 'react'

export const useForm = (options) => {
  const [data, setData] = useState(options?.initialValues || {})
  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    options.onChange(e)
    setData({
      ...data,
      [e.target.id || e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const validations = options?.validations
    if (validations) {
      let valid = true
      const newErrors = {}

      for (const key in validations) {
        const value = data[key]
        const validation = validations[key]

        if (validation?.required && !value) {
          valid = false
          newErrors[key] = true
        }
      }

      if (!valid) {
        setErrors(newErrors)
        return
      }
    }

    setErrors({})

    if (options?.onSubmit) {
      options.onSubmit()
    }
  }

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
  }
}
