import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import './i18n'

import App from './app'

ReactDOM.render(
  <>
    <App />
  </>,

  document.getElementById('root')
)
