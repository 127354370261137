import { TextField } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'

const PageNumber = ({ data, errors, onChange, t, type }) => (
  <>
    {type === 'page' && (
      <TextField
        id="start"
        type="text"
        label={t('form.pageNumber')}
        helperText={t('form.pageNumberHelp')}
        placeholder={t('form.pageNumberPlaceholder')}
        variant="outlined"
        value={data.start}
        onChange={onChange}
        error={errors.start}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )}

    {type === 'url' && (
      <TextField
        id="start"
        type="text"
        label={t('form.url')}
        placeholder={t('form.urlPlaceholder')}
        variant="outlined"
        value={data.start}
        onChange={onChange}
        error={errors.start}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )}

    {(type === 'msj' || type === 'custom') && (
      <>
        <TextField
          id="start"
          type="text"
          label={t('form.start')}
          placeholder="1"
          variant="outlined"
          value={data.start}
          onChange={onChange}
          error={errors.start}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          id="end"
          type="number"
          label={t('form.end')}
          variant="outlined"
          value={data.end}
          onChange={onChange}
          helperText={t('form.endHelp')}
          placeholder="100"
          error={errors.end}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </>
    )}
  </>
)

export default withNamespaces()(PageNumber)
