import { withNamespaces } from 'react-i18next'
import { makeStyles, Typography } from '@material-ui/core'

import InfoCard from './InfoCard'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',

    [theme.breakpoints.only('md')]: {
      justifyContent: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },

    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
    },
  },
  text: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      padding: '0 20px',
    },
  },
  pos: {
    marginBottom: 15,
  },
}))

const Infos = ({ t, onTypeChange, type }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <Typography color="textSecondary">.｡*ﾟ+.*.｡ଘ( ᐛ ) ଓ+..｡*ﾟ+</Typography>

        <Typography color="textSecondary" className={classes.pos}>
          {t(type ? 'info.selectAgain' : 'info.select')}
        </Typography>
      </div>

      <div className={classes.row}>
        <InfoCard
          label={t('info.msj')}
          forLabel={t('info.forLandingPages')}
          useCaseLabel={t('info.msjUseCase')}
          onClick={() => onTypeChange('msj')}
          selected={type === 'msj'}
        />
        <InfoCard
          label={t('info.custom')}
          forLabel={t('info.forRedirectService')}
          useCaseLabel={t('info.customUseCase')}
          onClick={() => onTypeChange('custom')}
          selected={type === 'custom'}
        />
      </div>

      <div className={classes.row}>
        <InfoCard
          label={t('info.page')}
          forLabel={t('info.forPage')}
          useCaseLabel={t('info.pageUseCase')}
          onClick={() => onTypeChange('page')}
          selected={type === 'page'}
        />
        <InfoCard
          label={t('info.url')}
          forLabel={t('info.forURL')}
          useCaseLabel={t('info.urlUseCase')}
          onClick={() => onTypeChange('url')}
          selected={type === 'url'}
        />
      </div>
    </div>
  )
}

export default withNamespaces()(Infos)
