import { useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'

const AppIdUrl = ({ data, errors, onChange, t, type }) => {
  useEffect(() => {
    onChange({ target: { id: 'url', value: null } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <div style={{ paddingBottom: '24px' }}>
      {type === 'custom' && (
        <TextField
          id="url"
          label={t('form.custom')}
          placeholder={t('form.customPlaceholder')}
          helperText={t('form.customHelp')}
          variant="outlined"
          value={data.url}
          onChange={onChange}
          error={errors.url}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}

      {type === 'url' && (
        <TextField
          id="url"
          label={t('form.url')}
          placeholder={t('form.urlPlaceholder')}
          variant="outlined"
          value={data.url}
          onChange={onChange}
          error={errors.url}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}

      {(type === 'page' || type === 'msj') && (
        <TextField
          id="url"
          label={t('form.appId')}
          placeholder={t('form.appIdPlaceholder')}
          variant="outlined"
          value={data.url}
          onChange={onChange}
          error={errors.url}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </div>
  )
}

export default withNamespaces()(AppIdUrl)
