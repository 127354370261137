import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

import translationEN from './locales/en.json'
import translationFR from './locales/fr.json'

const resources = {
  en: { translation: translationEN },
  fr: { translation: translationFR },
}

i18n
  .use(detector)
  .use(reactI18nextModule)
  .init({
    resources,
    fallbackLng: 'fr',
    interpolation: { escapeValue: false },
  })

export default i18n
