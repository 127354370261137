import { withNamespaces } from 'react-i18next'
import { IconButton, Drawer, Divider, Button } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import DisplayList from './DisplayList'

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  emptyList: {
    padding: 20,
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 370,
  },
}))

const QrDrawer = ({ urls, open, toggleOpen, t }) => {
  const classes = useStyles()

  const getUrlsToCopy = () => {
    const urlsArray = urls.map((url) => url.shortUrl.replace('/qr', ''))
    return urlsArray.join('\n')
  }

  return (
    <Drawer variant="temporary" anchor="right" open={open}>
      <div className={classes.drawerHeader}>
        <IconButton onClick={toggleOpen}>
          <ChevronRightIcon />
        </IconButton>

        <CopyToClipboard text={getUrlsToCopy()}>
          <Button variant="outlined" startIcon={<FileCopyOutlinedIcon />}>
            {t('drawer.copy')}
          </Button>
        </CopyToClipboard>
      </div>
      <Divider />

      {urls.length > 0 ? (
        <DisplayList urlsforQR={urls} />
      ) : (
        <div className={classes.emptyList}>
          <p>{t('drawer.empty')}</p>
          <p>ଘ(੭ ᐛ )━☆ﾟ.*･｡ﾟ</p>
        </div>
      )}
    </Drawer>
  )
}

export default withNamespaces()(QrDrawer)
