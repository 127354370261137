import { useState, useEffect } from 'react'
import { Button, makeStyles, TextField, Typography } from '@material-ui/core'
import { withNamespaces } from 'react-i18next'

import { useForm } from '../../hooks/useForm'
import PageNumber from './PageNumber'
import AppIdUrl from './AppIdUrl'

const validations = {
  redirect: { required: true },
  url: { required: true },
  start: { required: true },
  end: { required: true },
}
const validationUrl = {
  redirect: { required: true },
  url: { required: true },
}
const validationPage = {
  redirect: { required: true },
  url: { required: true },
  start: { required: true },
}

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 100,

    [theme.breakpoints.down('md')]: {
      margin: '40px 0 20px',
    },

    '& .MuiTextField-root': {
      marginBottom: theme.spacing(1),
      width: '300px',
    },

    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
  },
  button: {
    backgroundColor: '#3f6cb3',
  },
  hidden: {
    height: 0,
    visibility: 'hidden',
  },
}))

const Form = ({ linkGenerator, t, type, onChange }) => {
  const [submitted, setSubmitted] = useState(false)
  const classes = useStyles()

  const onSubmit = (data) => {
    const url = data.url
      .replace('https://', '')
      .replace('http://', '')
      .replace('www.', '')
    data.url = url

    linkGenerator(data)
    setSubmitted(!submitted)
  }

  const getValidation = (type) => {
    switch (type) {
      case 'page':
        return validationPage;
      case 'url':
        return validationUrl;
      default:
        return validations;
    }
  }

  const { data, handleChange, handleSubmit, errors } = useForm({
    validations: getValidation(type),
    onSubmit: () => onSubmit(data),
    initialValues: {
      start: '',
      end: '',
    },
    onChange: onChange,
  })

  useEffect(() => {
    handleChange({ target: { id: 'redirect', value: type } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography color="textSecondary">٩( ᐛ )و</Typography>

      <Typography color="textSecondary" style={{ marginBottom: '34px' }}>
        {t('form.info')}
      </Typography>

      <TextField
        id="mockType"
        label={t('redirect.type')}
        variant="outlined"
        value={t(`redirect.${type}`)}
        readOnly
        style={{ pointerEvents: 'none' }}
      />

      <AppIdUrl
        data={data}
        errors={errors}
        onChange={handleChange}
        type={type}
      />

      {type !== 'url' && (
        <PageNumber
          data={data}
          errors={errors}
          onChange={handleChange}
          type={type}
        />
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        {t('form.submit')}
      </Button>
    </form>
  )
}

export default withNamespaces()(Form)
