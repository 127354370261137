import { Button, IconButton } from '@material-ui/core'
import QrCodeIcon from '@mui/icons-material/QrCode'
import { makeStyles } from '@material-ui/core/styles'

import i18n from '../i18n'
import { ReactComponent as Logo } from '../assets/logo.svg'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 40px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      padding: 20,
    },
  },
  iconMenu: {
    marginLeft: 10,
  },
  logo: {
    width: 250,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
  },
  button: {
    backgroundColor: '#3f6cb3',
  },
}))

const Header = ({ onClick }) => {
  const classes = useStyles()

  const handleLangChange = (lng) => i18n.changeLanguage(lng)

  return (
    <div className={classes.header}>
      <Logo className={classes.logo} />

      <div>
        {i18n.language.includes('en') ? (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => handleLangChange('fr')}
          >
            Français
          </Button>
        ) : (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => handleLangChange('en')}
          >
            English
          </Button>
        )}

        <IconButton
          className={classes.iconMenu}
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={onClick}
        >
          <QrCodeIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default Header
