import { useState } from 'react'
import { withNamespaces } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

import { Form, QrDrawer, Header, Infos } from './components'
import { linkGenerator } from './utils'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontSize: 23,
    textAlign: 'center',
    marginBottom: 64,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      padding: '0px 20px',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  button: {
    backgroundColor: '#3f6cb3',
  },
  layout: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
  },
}))

const App = ({ t }) => {
  const [urls, setUrls] = useState([])
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('')

  const classes = useStyles()

  const setupLink = (data) => {
    const urls = linkGenerator(data.redirect, data.url, data.start, data.end)
    setUrls(urls)
    setOpen(!open)
  }

  return (
    <>
      <Header onClick={() => setOpen(!open)} />

      <div className={classes.layout}>
        <h1 className={classes.title}>{t('title')}</h1>

        <div className={classes.row}>
          <Infos
            onTypeChange={(type) => {
              setType(type)
              setOpen(false)
            }}
            type={type}
          />

          {type && (
            <Form
              linkGenerator={setupLink}
              type={type}
              onChange={() => setOpen(false)}
            />
          )}
        </div>
      </div>

      <QrDrawer urls={urls} toggleOpen={() => setOpen(!open)} open={open} />
    </>
  )
}

export default withNamespaces()(App)
