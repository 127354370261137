import { useRef, useState } from 'react'
import QRCode from 'qrcode.react'
import { withNamespaces } from 'react-i18next'
import { Button, ButtonGroup, makeStyles, FormHelperText, Typography } from '@material-ui/core'

import { ReactComponent as Check } from '../../assets/check.svg'
import { downloadQRPng, downloadSVG } from '../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),

    '& .MuiButtonGroup-root': {
      margin: theme.spacing(2),
    },
  },
  firstButton: {
    marginRight: theme.spacing(1),
  },
  preview: {
    textAlign: 'center',
    padding: 20,

    '& .MuiFormHelperText-root': {
      textAlign: 'center',
    },
  },
  button: {
    backgroundColor: '#3f6cb3',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}))

const DisplayList = ({ urlsforQR, t }) => {
  const [qrUrl, setQrUrl] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [pngDownloaded, setPngDownloaded] = useState([])
  const [svgDownloaded, setSvgDownloaded] = useState([])

  const svgRef = useRef()
  const pngRef = useRef()

  const classes = useStyles()

  // setTimeout necessary
  const setupDownloadSVG = (url) => {
    setDisabled(true)
    setQrUrl(url.qrLink)
    setTimeout(() => {
      downloadSVG(url, svgRef)
      setSvgDownloaded(svgDownloaded.concat(url))
      setDisabled(false)
    }, 500)
  }

  // setTimeout necessary
  const setupDownloadPNG = (url) => {
    setDisabled(true)
    setQrUrl(url.qrLink)
    setTimeout(() => {
      downloadQRPng(url, pngRef.current.firstChild)
      setPngDownloaded(pngDownloaded.concat(url))
      setDisabled(false)
    }, 500)
  }

  return (
    <>
      <div className={classes.preview}>
        <div ref={svgRef}>
          <QRCode
            value={qrUrl}
            size={100}
            fgColor="#000"
            bgColor="#fff"
            includeMargin={true}
            renderAs="svg"
          />
        </div>

        <FormHelperText>{t('drawer.preview')}</FormHelperText>
      </div>

      <div ref={pngRef}>
        <QRCode
          value={qrUrl}
          size={2000}
          fgColor="#000"
          bgColor="#fff"
          includeMargin={true}
          style={{ display: 'none' }}
        />
      </div>

      {urlsforQR.map((url, i) => (
        <div className={classes.root} key={i}>
          <Typography
            variant="body2"
            component="p"
            style={{ cursor: 'pointer' }}
            onClick={() => setQrUrl(url.qrLink)}
          >
            {url.shortUrl}
          </Typography>

          <ButtonGroup ml={10} variant="contained" color="primary">
            <Button
              className={classes.button}
              ml={10}
              onClick={() => setupDownloadPNG(url)}
              disabled={disabled}
            >
              PNG
              {pngDownloaded.includes(url) && <Check className={classes.icon} />}
            </Button>

            <Button className={classes.button} onClick={() => setupDownloadSVG(url)} disabled={disabled}>
              SVG
              {svgDownloaded.includes(url) && <Check className={classes.icon} />}
            </Button>
          </ButtonGroup>
        </div>
      ))}
    </>
  )
}

export default withNamespaces()(DisplayList)
